/**
 * Document Node template.
 *
 * Each "media document" node in Drupal that has the destination set to "Inside Sandy Springs"
 * will use this template to generate a corresponding page with Gatsby.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import InteriorLayout from '../components/interior-layout';
import DocumentDetail from '../components/document-detail';

const DocumentNode = ({ data }) => {
  // Depending on how much content there is, show more or fewer sidebar cards.
  let totalLength = 0;
  let sidebarDepth = 4;
  totalLength += data?.mediaDocument?.name.length;
  totalLength += data?.mediaDocument?.field_notes?.length;

  if (totalLength > 2400 && totalLength < 4272) {
    sidebarDepth = 3;
  } else if (totalLength <= 2400) {
    sidebarDepth = 2;
  }

  return (
    <Layout>
      <InteriorLayout sidebarDepth={sidebarDepth}>
        <DocumentDetail
          title={data?.mediaDocument?.name}
          reference={
            data?.mediaDocument?.relationships?.field_reference
              ?.field_document_type_code
          }
          referenceNumber={data?.mediaDocument?.field_reference?.value}
          type={data?.mediaDocument?.relationships?.field_media_file?.filemime}
          status={data?.mediaDocument?.field_status}
          department={
            data?.mediaDocument?.relationships?.field_department?.name
          }
          createdDate={data?.mediaDocument?.created}
          revisionDate={data?.mediaDocument?.revision_created}
          email={data?.mediaDocument?.relationships?.uid?.name}
          uploadedBy={`${data?.mediaDocument?.relationships?.uid?.field_first_name} ${data?.mediaDocument?.relationships?.uid?.field_last_name}`}
          notes={data?.mediaDocument?.field_notes}
          url={data?.mediaDocument?.path?.alias}
          fileName={`${data.site?.siteMetadata?.drupalSiteURL}${data?.mediaDocument?.relationships?.field_media_file?.uri?.url}`}
        />
      </InteriorLayout>
    </Layout>
  );
};

DocumentNode.propTypes = {
  data: PropTypes.object
};

export const query = graphql`
  query DocumentNodeQuery($slug: String!) {
    site {
      siteMetadata {
        title
        drupalSiteURL
      }
    }
    mediaDocument(path: { alias: { eq: $slug } }) {
      name
      id
      path {
        alias
      }
      field_status
      field_notes
      field_reference {
        value
      }
      created(formatString: "M/DD/YY")
      revision_created(formatString: "M/DD/YY")
      relationships {
        field_media_file {
          filemime
          filename
          uri {
            url
          }
        }
        uid {
          name
          field_last_name
          field_first_name
        }
        field_reference {
          field_document_type_code
        }
        field_department {
          name
        }
      }
    }
  }
`;

export default DocumentNode;
