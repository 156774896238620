import React from 'react';
import PropTypes from 'prop-types';
import styles from './document-detail.module.scss';
import Link from '../link';
import Button from '../button';

const DocumentDetail = ({
  title,
  reference,
  referenceNumber,
  type,
  status,
  department,
  createdDate,
  revisionDate,
  uploadedBy,
  notes,
  fileName,
  email
}) => {
  const statusFormatted = `${status.charAt(0).toUpperCase()}${status.slice(1)}`;
  const fileType = type.replace('application/', '').toUpperCase();

  return (
    <div className={styles.documentContainer}>
      <div className={styles.documentHeaders}>
        <h1 className={styles.documentLibraryTitle}>
          Sandy Springs Document Library
        </h1>
        <Link className={styles.libraryLink} to="/using-document-library">
          Using the Library
        </Link>
      </div>
      <h2 className={styles.documentTitle}>{title}</h2>
      <div className={styles.documentInfoContainer}>
        <div className={styles.documentDetail}>
          <strong>Reference:</strong> {reference}
          {referenceNumber}
        </div>
        <div className={styles.documentDetail}>
          <strong>Type:</strong> {fileType}
        </div>
        <div className={styles.documentDetail}>
          <strong>Status:</strong> {statusFormatted}
        </div>
        <div className={styles.documentDetail}>
          <strong>Department:</strong> {department}
        </div>
        <div className={styles.documentDetail}>
          <strong>Created:</strong> {createdDate}
        </div>
        <div className={styles.documentDetail}>
          <strong>Revised:</strong> {revisionDate}
        </div>
        <div className={styles.documentDetail}>
          <strong>Uploaded By:</strong>{' '}
          <a className={styles.documentLink} href={`mailto:${email}`}>
            {uploadedBy}
          </a>
        </div>
        <div className={styles.documentNotes}>{notes}</div>
        <Button
          aria-label={`Download ${title}`}
          className={styles.documentButton}
          href={fileName}
          borderStyle="round"
          color="blue"
          type="button"
          textStyle="upper"
        >
          View Document
        </Button>
      </div>
    </div>
  );
};

DocumentDetail.propTypes = {
  title: PropTypes.string,
  reference: PropTypes.string,
  referenceNumber: PropTypes.string,
  type: PropTypes.string,
  status: PropTypes.string,
  department: PropTypes.string,
  createdDate: PropTypes.string,
  revisionDate: PropTypes.string,
  uploadedBy: PropTypes.string,
  notes: PropTypes.string,
  url: PropTypes.string,
  fileName: PropTypes.string,
  email: PropTypes.string
};

export default DocumentDetail;
